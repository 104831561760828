import * as React from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';
import './src/assets/_sass/global.scss';
import "lightbox-react/style.css"
import "react-datepicker/dist/react-datepicker.css"

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="//fonts.googleapis.com/css?family=Noto+Serif+JP:400,500,700&display=swap&subset=japanese" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Bellefair" />
        <link
          rel="icon"
          href={withPrefix('/images/common/favicon.ico')}
        />
      </Helmet>
      {element}
    </>
  );
};

export const onRouteUpdate = ({ location }) => {
  /**
   * アンカー付き遷移でスライダーがあるときの位置のズレを調整
   * （iPhoneで現象あり）
   */
  setTimeout(function () {
    if (location.hash) {
      const targetElement = document.getElementById(location.hash.replace('#', ''));

      const offset = window.pageYOffset;
      const rect = (targetElement) ? targetElement.getBoundingClientRect().top : -offset;

      //htmlタグのcss情報を取得。scroll-padding-top必須
      const html_element = document.getElementsByTagName("html");
      const scroll_padding_top = getComputedStyle(html_element[0]).scrollPaddingTop;
      const gap = parseInt(scroll_padding_top);

      const target = rect + offset - gap;
      window.scrollTo({
        top: target,
      });
    }
  }, 500);
}


