exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-draft-infoevent-index-tsx": () => import("./../../../src/templates/draft/infoevent/index.tsx" /* webpackChunkName: "component---src-templates-draft-infoevent-index-tsx" */),
  "component---src-templates-facility-fuyacho-nijo-index-tsx": () => import("./../../../src/templates/facility/fuyacho-nijo/index.tsx" /* webpackChunkName: "component---src-templates-facility-fuyacho-nijo-index-tsx" */),
  "component---src-templates-facility-gokomachi-ebisugawa-index-tsx": () => import("./../../../src/templates/facility/gokomachi-ebisugawa/index.tsx" /* webpackChunkName: "component---src-templates-facility-gokomachi-ebisugawa-index-tsx" */),
  "component---src-templates-facility-goshonishi-index-tsx": () => import("./../../../src/templates/facility/goshonishi/index.tsx" /* webpackChunkName: "component---src-templates-facility-goshonishi-index-tsx" */),
  "component---src-templates-facility-index-tsx": () => import("./../../../src/templates/facility/index.tsx" /* webpackChunkName: "component---src-templates-facility-index-tsx" */),
  "component---src-templates-facility-kamanza-nijo-index-tsx": () => import("./../../../src/templates/facility/kamanza-nijo/index.tsx" /* webpackChunkName: "component---src-templates-facility-kamanza-nijo-index-tsx" */),
  "component---src-templates-facility-marutamachi-index-tsx": () => import("./../../../src/templates/facility/marutamachi/index.tsx" /* webpackChunkName: "component---src-templates-facility-marutamachi-index-tsx" */),
  "component---src-templates-facility-nishijin-villa-index-tsx": () => import("./../../../src/templates/facility/nishijin-villa/index.tsx" /* webpackChunkName: "component---src-templates-facility-nishijin-villa-index-tsx" */),
  "component---src-templates-facility-takeyamachi-index-tsx": () => import("./../../../src/templates/facility/takeyamachi/index.tsx" /* webpackChunkName: "component---src-templates-facility-takeyamachi-index-tsx" */),
  "component---src-templates-news-detail-tsx": () => import("./../../../src/templates/news/detail.tsx" /* webpackChunkName: "component---src-templates-news-detail-tsx" */),
  "component---src-templates-news-paging-tsx": () => import("./../../../src/templates/news/paging.tsx" /* webpackChunkName: "component---src-templates-news-paging-tsx" */),
  "component---src-templates-reservation-index-tsx": () => import("./../../../src/templates/reservation/index.tsx" /* webpackChunkName: "component---src-templates-reservation-index-tsx" */)
}

